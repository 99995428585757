<template>
  <SasModal
    class="video-modal"
    @close="$emit('close')"
  >
    <h3 slot="header">
      Saiba como usar o Plano de Estudos
    </h3>

    <div
      slot="body"
      class="video-modal__body"
    >
      <iframe
        allow="accelerometer;
        autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        frameborder="0"
        height="480"
        src="https://www.youtube.com/embed/9dRdbLHKC8Q"
        width="720"
      />
    </div>

    <template slot="footer">
      <SasButton
        :size="buttonSize"
        theme="float"
        @click="$emit('close')"
      >
        {{ backButtonText }}
      </SasButton>

      <SasButton
        :size="buttonSize"
        @click="$emit('open-study-guide')"
      >
        {{ studyGuideButtonText }}
      </SasButton>
    </template>
  </SasModal>
</template>

<script>
export default {
  name: 'StudyGuideVideoModal',

  computed: {
    isSmallScreen() {
      return this.$mq.includes('small')
    },

    buttonSize() {
      return this.isSmallScreen ? 'regular' : 'big'
    },

    backButtonText() {
      return `Voltar ${this.isSmallScreen ? '' : 'para o resultado'}`
    },

    studyGuideButtonText() {
      return `Ver ${this.isSmallScreen ? '' : 'meu'} Plano de Estudos`
    },
  },
}
</script>

<style lang="sass">
.video-modal

  .modal

    +mq-m
      padding: 0

    &__title

      +mq-m
        margin: 0
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14)

        h3
          box-sizing: border-box
          padding: 16px 32px

  &__body
    margin-bottom: 48px

    +mq-l
      position: relative
      padding-bottom: 56.25%

    +mq-m
      margin-top: 24px

    iframe
      border: none
      padding: 16px 0
      -webkit-box-sizing: border-box
      box-sizing: border-box

      +mq-l
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        padding: 0

  .modal__footer
    justify-content: space-between
</style>
